import { TranslationResourceProvider } from 'Roblox';
import angular from 'angular';

const aliases = angular.module('aliases', ['aliasesAppTemplates', 'modal']).config([
  'languageResourceProvider',
  languageResourceProvider => {
    const translationProvider = new TranslationResourceProvider();
    const profileResources = translationProvider.getTranslationResource('Feature.Profile');

    languageResourceProvider.setTranslationResources([profileResources]);
  }
]);

export default aliases;
