import aliasesModule from '../aliasesModule';

function aliasesContainer(aliasesResources) {
  'ngInject';

  return {
    restrict: 'A',
    replace: false,
    templateUrl: aliasesResources.templateUrls.aliasesContainer
  };
}

aliasesModule.directive('aliasesContainer', aliasesContainer);

export default aliasesContainer;
