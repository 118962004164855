import aliasesModule from '../aliasesModule';

function aliasesFriendsService(httpService, aliasesResources, $filter) {
  'ngInject';

  return {
    getFriendsStatus: (currentUserId, requestUserIds) => {
      const params = {
        userIds: requestUserIds
      };

      const { url } = aliasesResources.httpUrlConfig.getFriendsStatus;

      aliasesResources.httpUrlConfig.getFriendsStatus.url = $filter('formatString')(url, {
        userId: currentUserId
      });

      return httpService.httpGet(aliasesResources.httpUrlConfig.getFriendsStatus, params);
    }
  };
}

aliasesModule.factory('aliasesFriendsService', aliasesFriendsService);

export default aliasesFriendsService;
