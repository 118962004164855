import aliasesModule from '../aliasesModule';

function aliasesLayoutService($log, languageResource) {
  'ngInject';

  const lang = languageResource;

  return {
    defaultLibrary: {
      currentProfileUserId: 0,
      currentUserTag: '',
      userTag: ''
    },
    aliasesLayout: {
      label: lang.get('Label.Alias'), // "Alias",
      changeAliasTile: lang.get('Label.ChangeAlias'), // "Change Alias",
      changeAliasDescription: lang.get('Description.ChangeAlias'), // "Only you can see it. Don't see personally identifiable information.",
      modalClose: lang.get('Action.Close'), // "Close",
      modalSave: lang.get('Action.Save'), // "Save",
      modalCancel: lang.get('Label.Cancel'), // "Save",
      isAliasesShown: false
    },
    changeAliasModalLayout: {
      isRequestFailed: false,
      displayError: lang.get('Message.AliasHasError'), // default error msg
      setUserTagResponses: {
        defaultError: lang.get('Message.AliasHasError'),
        moderationError: lang.get('Message.AliasIsModerated')
      }
    }
  };
}

aliasesModule.factory('aliasesLayoutService', aliasesLayoutService);

export default aliasesLayoutService;
