import aliasesModule from '../aliasesModule';

function contactsService($log, httpService, aliasesResources, $q) {
  'ngInject';

  return {
    getAliasesGuacConfig() {
      const data = {};
      return httpService.httpGet(aliasesResources.httpUrlConfig.getAliasesGuacConfig, data);
    },

    getUserTags(targetUserId) {
      const data = {
        targetUserIds: [targetUserId]
      };

      return httpService.httpPost(aliasesResources.httpUrlConfig.getTags, data);
    },

    setUserTag(targetUserId, userTag) {
      const data = {
        targetUserId,
        userTag
      };

      return httpService
        .httpPost(aliasesResources.httpUrlConfig.setUserTag, data, true)
        .then(function success(result) {
          if (result.data && result.data.status === aliasesResources.responseStatus.moderated) {
            return $q.reject(result);
          }
        });
    }
  };
}

aliasesModule.factory('contactsService', contactsService);

export default contactsService;
